import React from 'react'

const CoinbaseSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
    >
        {' '}
        <rect width={width} height={height} rx="2" fill={color} />
        <circle cx="512" cy="512" r="512" fill={color} />
        <path
            d="M516.3 361.83c60.28 0 108.1 37.18 126.26 92.47H764C742 336.09 644.47 256 517.27 256 372.82 256 260 365.65 260 512.49S370 768 517.27 768c124.35 0 223.82-80.09 245.84-199.28H642.55c-17.22 55.3-65 93.45-125.32 93.45-83.23 0-141.56-63.89-141.56-149.68.04-86.77 57.43-150.66 140.63-150.66z"
            fill={'white'}
        />
    </svg>
)

export default CoinbaseSvg
