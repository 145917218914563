import React from 'react'

const HuobiSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 800 800"
    >
        <rect width={width} height={height} rx="2" fill={color} />
        <g>
            <path
                className="st0"
                d="M480.5,248.1c0-113.2-54.6-210.8-96.4-242.8c0,0-2.9-1.7-2.9,2.9c-3.5,218.9-114.4,278.8-176,358.3
		c-141.1,184.7-9.9,386.8,123.7,424c74.9,20.9-17.4-37.2-29-159.1C286,483.3,480.5,370.6,480.5,248.1"
                fill={color}
            />
            <path
                className="st1"
                d="M553.7,323c-0.6-0.6-1.7-0.6-2.9,0c0,0,0,0-0.6,0.6c-2.3,27.3-32.5,86.5-70.3,140.5
		C351.6,647.6,424.8,735.9,466,783.5c23.8,27.9,0,0,59.8-28.5c4.6-2.3,116.2-61.6,128.3-195.7C666.4,428.7,583.3,346.8,553.7,323"
                fill={color}
            />
        </g>
    </svg>
)

export default HuobiSvg
