import React from 'react'

const BinanceUSSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 800 800"
    >
        <rect width={width} height={height} rx="2" fill={color} />

        <g id="Layer_x0020_1">
            <path
                className="st1"
                d="M85.1,400l-39.7,38.3L5.7,400l39.7-38.3L85.1,400z M181.3,307.3l67.8,65.4l39.7-38.3L181.3,231L73.8,334.6
		l39.7,38.3L181.3,307.3L181.3,307.3z M317,361.7L277.2,400l39.7,38.3l39.7-38.3L317,361.7L317,361.7z M181.3,492.7l-67.8-65.4
		l-39.7,38L181.3,569l107.5-103.7l-39.7-38.3L181.3,492.7L181.3,492.7z M181.3,438l39.7-38.3l-39.7-38.3l-39.7,38.3L181.3,438z
		 M395,492.7V465h25.3v27.7H395z M617.6,413.5c0,54.1-32.2,82.2-80.1,82.2c-47.6,0-80.1-28-80.1-80.5V307.3h21.6v106.6
		c0,39.9,21.9,62.4,58.9,62.4c35.6,0,57.9-20.8,57.9-61.1v-108h21.6v106.3H617.6L617.6,413.5z M731.7,389.8
		c42.8,8.9,62.7,24.1,62.7,52.8c0,32-27.4,52.8-65.7,52.8c-30.5,0-55.5-9.9-78.8-29.7l13.4-15.5c20.2,17.5,39.4,26.1,66.1,26.1
		c25.7,0,42.8-12.9,42.8-31.7c0-17.5-9.6-27.4-50-35.6c-44.2-9.2-64.7-23.1-64.7-53.8c0-29.7,26.7-50.8,63.3-50.8
		c28.1,0,48,7.6,67.5,22.8l-12.7,16.2c-17.8-14.2-35.6-20.1-55.5-20.1c-25,0-41.1,13.2-41.1,30C679.6,371.3,689.2,381.2,731.7,389.8
		L731.7,389.8z"
                fill={color}
            />
        </g>
    </svg>
)

export default BinanceUSSvg
