// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
    authDomain: process.env.REACT_APP_GOOGLE_AUTH_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_GOOGLE_AUTH_PROJECT_ID,
    storageBucket: process.env.REACT_APP_GOOGLE_AUTH_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_GOOGLE_AUTH_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_GOOGLE_AUTH_APP_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider();

export default app
