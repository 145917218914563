import React from 'react'

const PoloniexSpotSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 800 800"
    >
        <rect width={width} height={height} rx="2" fill={color} />
        <g id="Layer_x0020_1">
            <g id="_2498094866192">
                <polygon
                    className="st0"
                    points="727.6,211.4 727.4,400.4 563.7,305.8 400.2,211.1 564.1,116.9 727.8,22.5 		"
                />
                <polygon
                    className="st1"
                    points="400,400 399.8,588.9 236.3,494.4 72.8,399.6 236.5,305.4 400.2,211.1 		"
                />
                <polygon
                    className="st2"
                    points="73,210.7 73.2,21.7 236.7,116.5 400.2,211.1 236.5,305.4 72.8,399.6 		"
                />
                <polygon
                    className="st0"
                    points="72.4,588.6 72.8,399.6 236.3,494.4 399.8,588.9 236.1,683.3 72.2,777.5 		"
                />
                <polygon
                    className="st2"
                    points="563.7,305.8 400.2,211.1 400,400 399.8,588.9 563.3,683.7 726.8,778.3 727,589.3 727.4,400.4 		"
                />
            </g>
        </g>
    </svg>
)

export default PoloniexSpotSvg
