import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Pagination, Select } from 'components/ui'
import TableRowSkeleton from './loaders/TableRowSkeleton'
import Loading from './Loading'
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const DataTableExpandable = (props) => {
    const {
        skeletonAvatarColumns,
        columns,
        data,
        loading,
        onSort,
        subColumnName,
        skeletonAvatarProps,
    } = props
    const [expanded, setExpanded] = useState({})
    const [sorting, setSorting] = React.useState([])

    const handleSort = (column) => {
        const { accessor } = column.columnDef
        const isDesc = sorting.accessor === accessor && sorting.isSortedDesc
        const direction = isDesc ? 'asc' : 'desc'
        setSorting({
            accessor: accessor,
            isSorted: true,
            isSortedDesc: !isDesc,
        })

        if (!loading) {
            onSort(
                { order: direction, key: accessor },
                { accessor, clearSortBy: () => setSorting({}) }
            )
        }
    }

    const table = useReactTable({
        data,
        columns,
        state: {
            expanded,
            sorting,
        },
        onExpandedChange: setExpanded,
        onSortingChange: setSorting,
        getSubRows: (row) => row[subColumnName],
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    return (
        <Loading loading={loading && data.length !== 0} type="cover">
            <Table compact={true}>
                <THead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                    >
                                        {header.column.columnDef.sortable ? (
                                            <div
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    handleSort(header.column)
                                                }
                                            >
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext()
                                                )}
                                                <span>
                                                    <Sorter
                                                        sort={
                                                            header.column
                                                                .isSortedDesc
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        ) : (
                                            <div>
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext()
                                                )}
                                            </div>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </THead>
                {loading && data.length === 0 ? (
                    <TableRowSkeleton
                        columns={columns.length}
                        rows={10}
                        avatarInColumns={skeletonAvatarColumns}
                        avatarProps={skeletonAvatarProps}
                    />
                ) : (
                    <TBody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <Tr key={row.id} className={row.depth > 0 ? 'bg-slate-100 dark:bg-slate-900' : ''}>
                                    {row.getVisibleCells().map((cell, index) => {
                                        return (
                                            <Td
                                                key={cell.id} style={(index === 1 && cell.row.depth > 0) ? { paddingLeft: 30 * cell.row.depth + 'px' } : {}}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                    </TBody>
                )}
            </Table>
            {!loading && data.length === 0 && (
                <div className="mt-8">
                    <div className="flex items-center justify-center">
                        No records found!
                    </div>
                </div>
            )}
        </Loading>
    )
}

DataTableExpandable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    // onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
}

DataTableExpandable.defaultProps = {
    data: [],
    columns: [],
    loading: false,
}

export default DataTableExpandable
