import React from 'react'

const PoloniexFutureSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 800 800"
    >
        <rect width={width} height={height} rx="2" />
        <g>
            <path
                className="st0"
                d="M61.8,399.8c0-0.9-0.1-1.7-0.1-2.6c0-78.2,0-156.5,0.1-234.8c0-0.8,0.1-1.5,0.1-2.3c0.8-1.2,0.4-2.6,0.4-3.9
		c0.1-47.6,0.1-95.3,0.2-142.9c0-1.3-0.3-2.6,0.5-3.8c21.3,12.3,42.7,24.6,64,36.9c20.8,12.1,41.6,24.2,62.4,36.2
		c23,13.4,46.1,26.6,69.1,40c19.1,11,38.1,22,57.1,33.1c19.6,11.3,39,22.5,58.6,33.9c8.2,4.8,16.5,9.7,24.7,14.5
		c0.4,0.3,1,0.3,1.6,0.4c5.3,3.9,11.2,6.8,16.9,10.1c27,15.7,54,31.3,81,46.9c38.6,22.4,77.1,44.7,115.8,67.1
		c35.1,20.3,70.3,40.5,105.3,60.8c6.5,3.7,13.1,7.1,19.1,11.4c0,10.1-0.1,20.2-0.1,30.3c0,12.7,0.1,25.3,0.1,37.9c0,1-0.1,2-0.1,3.1
		c0,47,0,94.1-0.1,141c0,0.9-0.1,1.9-0.1,2.8c-0.8,1.4-0.4,2.8-0.4,4.2c-0.1,55.8-0.1,111.5-0.2,167.3c0,1.2,0.3,2.5-0.6,3.6
		c-8.2-4.7-16.5-9.4-24.7-14.1c-28.7-16.6-57.3-33.2-86-49.8c-24.4-14.1-48.8-28.2-73.2-42.4c-24.7-14.3-49.3-28.4-74-42.7
		c-22.9-13.3-45.8-26.6-68.6-39.9c-3.7-2.1-7.2-4.3-10.8-6.5c-0.1-1.4-0.2-2.8-0.2-4.3c-0.1-17.7-0.1-35.4-0.2-53.1
		c0.9-1.1,0.4-2.4,0.4-3.6c0-43.8,0-87.6,0-131.5c0-1-0.1-2-0.1-3.1s0.1-2.3,0.1-3.4c0-26.7,0-53.3,0-80c0-18,0.1-36.1,0.1-54.2
		c0-18.3,0-36.5,0-54.8c0-1.3,0.5-3.2-2-1.8c-14.7,8.5-29.4,17-44.1,25.4c-28,16.2-56,32.3-84,48.5c-16.7,9.6-33.3,19.1-50,28.7
		c-23.3,13.4-46.5,26.9-69.8,40.3c-20.6,11.8-41.2,23.7-61.8,35.6C79.6,389.8,70.6,394.8,61.8,399.8z"
                fill={color}
            />
            <path
                className="st1"
                d="M399.1,595.8c-2,2.5-5.1,3.5-7.7,5c-23.5,13.6-47,27.1-70.5,40.7c-26.6,15.3-53.2,30.6-79.9,46
		c-23.4,13.4-46.7,27-70.1,40.3c-26,15-52.1,29.9-78.2,44.9c-9,5.2-18,10.5-27,15.6c-0.8,0.4-1.5,0.8-2.2,1.2
		c-1.5,0.9-2.3,0.5-2.2-1.3c0-0.7,0-1.4,0-2c0-94.3-0.3-188.7,0.2-283c0.2-34.4,0-68.6,0.4-103c3,0.9,5.4,2.6,8.1,4.2
		c23.2,13.4,46.4,27,69.7,40.4c19.6,11.3,39.1,22.5,58.7,33.9c18.2,10.5,36.4,21.3,54.6,31.8c24.9,14.5,49.9,28.7,74.9,43.2
		c20.5,11.8,41.1,23.6,61.6,35.6C392.5,591.4,396.4,592.8,399.1,595.8z"
                fill={color}
            />
            <path
                className="st1"
                d="M738.7,400.9c-6-4.3-12.8-7.7-19.1-11.4c-35-20.3-70.3-40.5-105.3-60.8c-38.6-22.4-77.1-44.7-115.8-67.1
		c-27-15.6-54-31.2-81-46.9c-5.7-3.3-11.6-6.2-16.9-10.1c13.9-8,27.9-15.9,41.8-23.9c18.5-10.5,36.9-21.2,55.4-31.8
		c18.5-10.6,37.1-21.3,55.5-32c18.2-10.5,36.4-20.9,54.6-31.4c17.5-10.1,35-20.2,52.6-30.4c18.2-10.5,36.4-20.9,54.6-31.4
		c7-4,13.9-8,20.8-12.1c2.8-1.6,3.2-1.4,3.2,1.6c0,128.9,0,257.8,0,386.6c0,0.3,0,0.8,0,1.1C739,401,738.9,400.9,738.7,400.9z"
                fill={color}
            />
            <path
                className="st2"
                d="M399.1,595.8c-2.7-3.1-6.5-4.4-9.9-6.5c-20.5-12-41-23.8-61.6-35.6c-24.9-14.4-49.9-28.7-74.9-43.2
		c-18.2-10.5-36.4-21.3-54.6-31.8c-19.6-11.3-39.1-22.6-58.7-33.9c-23.2-13.4-46.4-27-69.7-40.4c-2.6-1.5-5.1-3.3-8.1-4.2
		c0-0.2,0-0.3,0-0.6c8.8-5,17.8-10,26.5-15.1c20.6-11.8,41.2-23.6,61.8-35.6c23.3-13.4,46.5-26.9,69.8-40.3
		c16.7-9.6,33.3-19.1,50-28.7c28.1-16.2,56-32.3,84-48.5c14.7-8.5,29.4-16.9,44.1-25.4c2.6-1.5,2,0.4,2,1.8c0,18.3,0,36.5,0,54.8
		c0,18-0.1,36.1-0.1,54.2c0,26.7,0,53.3,0,80c0,1.1-0.1,2.3-0.1,3.4c-0.8,1.2-0.4,2.6-0.4,3.9c0,43.5,0,87.1,0,130.6
		c0,1.2,0.1,2.5,0.1,3.7c0,17.7,0.1,35.4,0.2,53.1c0,1.4,0.1,2.8,0.2,4.3C399.7,595.8,399.4,595.8,399.1,595.8z"
                fill={color}
            />
            <path
                className="st3"
                d="M737.4,791.1c0.9-1,0.6-2.4,0.6-3.6c0.1-55.8,0.1-111.5,0.2-167.3c0-1.4-0.3-2.8,0.4-4.2
		c0,1.1,0.1,2.3,0.1,3.4c0,44,0,87.9,0,131.8c0,12.6-0.1,25.1-0.3,37.7C738.4,789.7,739.6,791.4,737.4,791.1z"
                fill={color}
            />
            <path
                className="st4"
                d="M63,9.6c-0.9,1.2-0.5,2.6-0.5,3.8c-0.1,47.6-0.1,95.3-0.2,142.9c0,1.3,0.3,2.6-0.4,3.9c0-1-0.1-2-0.1-3.1
		c0-44.1,0-88.2,0-132.3c0-4.5,0.1-9.1,0.3-13.6C62,10.7,60.9,9,63,9.6z"
                fill={color}
            />
            <path
                className="st5"
                d="M738.7,400.9c0.2,0,0.3,0.1,0.4,0.1c0,22.1,0.1,44.2,0.1,66.3c0,0.7,0.1,1.4-0.4,1.9c0-12.7-0.1-25.3-0.1-37.9
		C738.6,421.1,738.7,411,738.7,400.9z"
                fill={color}
            />
            <path
                className="st6"
                d="M399.8,538.5c0-1.2-0.1-2.5-0.1-3.7c0-43.5,0-87.1,0-130.6c0-1.3-0.3-2.6,0.4-3.9c0,1,0.1,2,0.1,3.1
		c0,43.8,0,87.6,0,131.5C400.2,536.1,400.5,537.4,399.8,538.5z"
                fill={color}
            />
        </g>
    </svg>
)

export default PoloniexFutureSvg
