import React from 'react'

const UserSvg = (props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="24" height="24" rx="2" fill={props.color} />

        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 15.73 14.225 C 14.306 13.592 14.042 13.012 14.042 12.432 C 14.042 11.798 14.464 11.219 14.939 10.796 C 15.783 10.005 16.258 8.897 16.258 7.631 C 16.258 5.258 14.834 3.2 12.248 3.2 C 9.664 3.2 8.239 5.258 8.239 7.631 C 8.239 8.897 8.714 10.005 9.558 10.796 C 10.033 11.271 10.455 11.851 10.455 12.432 C 10.455 13.065 10.244 13.592 8.767 14.225 C 6.657 15.122 4.652 16.124 4.6 18.023 C 4.6 19.29 5.549 20.345 6.763 20.345 L 17.735 20.345 C 18.948 20.345 19.898 19.29 19.898 18.023 C 19.845 16.177 17.841 15.175 15.73 14.225 Z"
            fill="white"
        ></path>
    </svg>
)

export default UserSvg
