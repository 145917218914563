import React from 'react'

const TeamSvg = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect
            width="24"
            height="24"
            rx="2"
            fill={props.color}
        ></rect>
        <g>
            <path
                d="M 11.938 11.754 C 14.009 11.754 15.688 13.433 15.688 15.504 L 15.688 20.004 L 8.188 20.004 L 8.188 15.504 C 8.188 13.433 9.867 11.754 11.938 11.754 Z M 6.906 14.009 C 6.786 14.412 6.713 14.826 6.696 15.245 L 6.688 15.504 L 6.688 20.004 L 4.438 20.004 L 4.438 16.629 C 4.438 15.289 5.447 14.164 6.777 14.019 L 6.906 14.009 Z M 16.971 14.009 C 18.357 14.093 19.438 15.242 19.438 16.629 L 19.438 20.004 L 17.188 20.004 L 17.188 15.504 C 17.188 14.985 17.113 14.482 16.971 14.009 Z M 7.063 9.504 C 8.507 9.504 9.41 11.067 8.688 12.317 C 8.352 12.898 7.732 13.254 7.063 13.254 C 5.619 13.254 4.716 11.691 5.438 10.442 C 5.774 9.86 6.394 9.504 7.063 9.504 Z M 16.813 9.504 C 18.257 9.504 19.16 11.067 18.438 12.317 C 18.102 12.898 17.482 13.254 16.813 13.254 C 15.369 13.254 14.466 11.691 15.188 10.442 C 15.524 9.86 16.144 9.504 16.813 9.504 Z M 11.938 5.004 C 14.247 5.004 15.692 7.503 14.537 9.504 C 14.001 10.432 13.011 11.004 11.938 11.004 C 9.629 11.004 8.184 8.505 9.339 6.504 C 9.876 5.576 10.866 5.004 11.938 5.004 Z"
                fill="#fff"
            ></path>
            <path fill="none" d="M0 0h24v24H0z"></path>
        </g>
    </svg>
)

export default TeamSvg
