import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        refreshToken: '',
        expirationTime: '',
        signedIn: false,
        sync_time: '',
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.refreshToken = ''
            state.expirationTime = ''
            state.sync_time = ''
        },
        setToken: (state, action) => {
            state.token = action.payload.accessToken
        },
        setSnapshotSyncTime: (state, action) => {
            state.sync_time = action.payload
        },
    },
})

export const {
    onSignInSuccess,
    onSignOutSuccess,
    setToken,
    setSnapshotSyncTime,
} = sessionSlice.actions

export default sessionSlice.reducer
