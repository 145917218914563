import React from 'react'

const CoinFlexSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 800 800"
    >
        <rect width={width} height={height} rx="2" fill={color} />

        <g>
            <path
                className="st0"
                d="M129.2,395.8c4.5,115.3,56.8,200,161.9,250.3c6.7,3.1,11.6,7.1,15.8,13.1c18.9,26.9,38.5,53.7,57.4,80.6
		c15.1,21.8,6,48.5-17.6,54.3c-5.1,1.3-10.2,1.1-15.4-0.2c-154.8-35.2-255.6-130-304.8-279.7C6.7,454.1,2.3,391.5,15.6,329.6
		C49.3,174,165.7,42.6,327.8,10.3c19.1-3.8,29.6-0.7,41.4,16.3c63.9,92.9,129.6,184.6,194.2,276.8c57.2,81.5,32.3,193.7-53.7,243.2
		c-20.5,11.8-45.4,9.8-63.5-5.1c-19.6-16.3-28.1-39.2-21.6-59c2.7-8,6.9-14,14.3-18.9c37-24,47.4-75.3,22-111.8
		c-47.2-67.9-95.1-135.2-142.5-202.8c-3.3-4.7-6.2-6.2-11.8-3.6c-89.7,42.5-155,105.5-174.6,207.3c-1.3,6.5-2.2,13.1-2.7,19.8
		C128.8,380.2,129.2,388,129.2,395.8z"
                fill={color}
            />
            <path
                className="st0"
                d="M792.1,429.8c-1.8,77.9-43,168.8-115.3,246.3c-59.2,63.2-132.3,101.5-218,115.6c-8.5,1.3-13.4-0.4-18.5-7.6
		c-67.7-96.6-135.6-192.8-203.5-289.2c-56.1-79.5-30.5-193.3,54.1-241.6c20.9-12,45.6-10,63.5,4.7c20.5,16.9,28.7,40.3,21.6,61
		c-2,6.2-5.6,10.5-11.6,13.8c-45.6,24.7-54.3,76.8-22.9,118.2c34.3,45.2,65.9,92.4,98.6,138.7c14,20,28.3,39.9,42.1,60.1
		c3.6,5.1,6.9,7.1,12.7,4.5c86.8-41,152.3-101.3,172.3-198.8c25.6-124.2-39.9-248.7-155.4-301c-7.8-3.6-13.6-8.2-18.5-15.1
		c-18.3-26.1-37-51.9-55.4-77.9c-7.8-10.9-10.9-22.7-6.2-35.6c5.8-16,21.2-23.6,39.9-19.6c110.9,23.6,196.6,83.9,257.6,179
		C771.1,250.6,791.9,324.5,792.1,429.8z"
                fill={color}
            />
        </g>
    </svg>
)

export default CoinFlexSvg
