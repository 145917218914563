import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

function useAuthority(userAuthority = '', authority = [], userAccountType = '', accountType = [], emptyCheck = false) {
    const roleMatched = useMemo(() => {
        return (authority.some((role) => userAuthority.includes(role)) && accountType.some((account_type) => userAccountType.includes(account_type)))
    }, [authority, userAuthority, accountType, userAccountType])

    if (
        isEmpty(authority) ||
        isEmpty(userAuthority) ||
        typeof authority === 'undefined'
    ) {
        return !emptyCheck
    }

    return roleMatched
}

export default useAuthority
