import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/ui'
import TableRowSkeleton from './loaders/TableRowSkeleton'
import Loading from './Loading'
import { useTable, useSortBy } from 'react-table'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const DataTableNoPagination = (props) => {
    const {
        skeletonAvatarColumns,
        columns,
        data,
        loading,
        onSort,
        skeletonAvatarProps,
    } = props

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
        useTable(
            {
                columns,
                data,
                manualPagination: true,
                manualSortBy: true,
            },
            useSortBy
        )

    const handleSort = (column) => {
        if (!loading) {
            const { id, isSortedDesc, toggleSortBy, clearSortBy } = column
            const sortOrder = isSortedDesc ? 'desc' : 'asc'
            toggleSortBy(!isSortedDesc)
            onSort?.({ order: sortOrder, key: id }, { id, clearSortBy })
        }
    }

    return (
        <Loading loading={loading && data.length !== 0} type="cover">
            <Table {...getTableProps()} compact={true}>
                <THead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps()}>
                                    {column.render('Header') &&
                                        (column.sortable ? (
                                            <div
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    handleSort(column)
                                                }
                                            >
                                                {column.render('Header')}
                                                <span>
                                                    <Sorter
                                                        sort={
                                                            column.isSortedDesc
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        ) : (
                                            <div>{column.render('Header')}</div>
                                        ))}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </THead>
                {loading && data.length === 0 ? (
                    <TableRowSkeleton
                        columns={columns.length}
                        rows={10}
                        avatarInColumns={skeletonAvatarColumns}
                        avatarProps={skeletonAvatarProps}
                    />
                ) : (
                    <TBody {...getTableBodyProps()}>
                        {rows?.map((row, i) => {
                            prepareRow(row)
                            return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <Td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                    </TBody>
                )}
            </Table>
            {!loading && data.length === 0 && (
                <div className="mt-8">
                    <div className="flex items-center justify-center">
                        No records found!
                    </div>
                </div>
            )}
        </Loading>
    )
}

DataTableNoPagination.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    autoResetSelectedRows: PropTypes.bool,
}

DataTableNoPagination.defaultProps = {
    data: [],
    columns: [],
    selectable: false,
    loading: false,
    autoResetSelectedRows: true,
}

export default DataTableNoPagination
