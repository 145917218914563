import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Modal = (props) => {
    const { open, onClose, children } = props;

    if (!open) return null;

    const handleOverlayClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div>
            <div
                className={classNames('fixed top-16 left-0 w-full h-full backdrop-blur bg-black opacity-50 z-10')}
                onClick={handleOverlayClick}
            ></div>
            <div className="fixed mt-5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-[#0F1526] p-8 rounded-md z-10 scale-75">
                {onClose && (
                    <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onClose}>
                        &times;
                    </button>
                )}
                <div className='w-[50vh] mx-auto'>{children}</div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export default Modal;
