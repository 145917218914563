import React from 'react'

const BinanceSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width={width} height={height} rx="2" fill={color} />
        <path
            d="M8.89274 10.7233L11.9999 7.61624L15.1087 10.725L16.9167 8.91698L11.9999 4L7.08477 8.91534L8.89274 10.7233Z"
            fill="white"
        />
        <path
            d="M7.61596 11.9997L5.80803 10.1918L4 11.9998L5.80794 13.8078L7.61596 11.9997Z"
            fill="white"
        />
        <path
            d="M8.89274 13.2767L11.9999 16.3838L15.1086 13.2752L16.9176 15.0821L16.9167 15.0831L11.9999 20L7.08212 15.0823L8.89274 13.2767Z"
            fill="white"
        />
        <path
            d="M18.192 13.8086L20 12.0006L18.1921 10.1926L16.384 12.0007L18.192 13.8086Z"
            fill="white"
        />
        <path
            d="M13.8338 11.9991H13.8345L11.9999 10.1644L10.1644 11.9999L10.1669 12.0026L11.9999 13.8356L13.8345 12.0009L13.8354 11.9999L13.8338 11.9991Z"
            fill="white"
        />
    </svg>
)

export default BinanceSvg
