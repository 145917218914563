import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { setError } from 'store/error/errorSlice'
import { MANAGER } from 'constants/roles.constant'

const unauthorizedCode = [401]
const unavailableCodes = [400, 500]

// baseUrl for API call from environment variable.
// const baseUrl = process.env.REACT_APP_API_URL

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
    headers: {
        'Content-Type': 'application/json',
    },
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        const userRole = store.getState()?.auth.user.role
        let is_snapshot, sync_time, snapshot
        if (persistData?.auth?.session?.sync_time) {
            is_snapshot = 1
            sync_time = persistData.auth.session.sync_time
            snapshot = true
        } else {
            is_snapshot = 0
            sync_time = ''
            snapshot = false
        }

        if (snapshot && sync_time && userRole === MANAGER) {
            config.headers['is_snapshot'] = is_snapshot
            config.headers['sync_time'] = sync_time
        }

        let accessToken = persistData.auth.session.token

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (response) {
            switch (response.status) {
                case 401:
                    store.dispatch(onSignOutSuccess())
                    break
                case 400:
                    break
                case 403:
                    store.dispatch(
                        setError({
                            code: 400,
                            message:
                                'Something went wrong, Please try again later',
                        })
                    )
                    break
                case 500:
                    store.dispatch(
                        setError({
                            code: 500,
                            message:
                                'Something went wrong, Please try again later',
                        })
                    )
                    break
                default:
                    break
            }
        }

        return Promise.reject(error)
    }
)

export default BaseService
