import React from 'react'
import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'

const AuthorityCheck = (props) => {
    const { userAuthority = '', authority = [], userAccountType = '', accountType = [], children } = props

    const roleMatched = useAuthority(userAuthority, authority, userAccountType, accountType)

    return roleMatched ? children : <></>
}

AuthorityCheck.propTypes = {
    userAuthority: PropTypes.string,
    authority: PropTypes.array,
    userAccountType: PropTypes.string,
    accountType: PropTypes.array,
     
}

export default AuthorityCheck
