import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {
    apiGetUserHFM,
    apiSignIn,
    apiSignInMetaMask,
    apiSignInWithGoogle,
    apiSignOut,
    apiRegister,
    apiGoogleSignUp,
    apiMetamaskSignUp,
    // apiSignUp,
} from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import firebaseErrorHandler from 'utils/firebaseErrorHandler'
import {
    setSnapshot,
    setSyncTime,
} from 'views/snapshots/SnapshotList/store/stateSlice'
import { setSnapshotSyncTime } from 'store/auth/sessionSlice'
import { SUPER_ADMIN, MANAGER } from 'constants/roles.constant'
import { toast, Notification } from 'components/ui'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
        dispatch(setSyncTime(0))
        dispatch(setSnapshot(false))
        dispatch(setSnapshotSyncTime(''))
        localStorage.removeItem(REDIRECT_URL_KEY)
    }

    const { token, signedIn } = useSelector((state) => {
        if (state.auth.session) {
            return state.auth.session
        } else {
            handleSignOut()
            return {}
        }
    })

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.user) {
                const tokens = resp.user.stsTokenManager
                // Call apiGetUserHFM with email address and access token to get user information
                const { accessToken } = tokens
                const data = {
                    google_token: accessToken,
                }
                const response = await apiGetUserHFM(data)

                if (response.status !== 200) {
                    return {
                        status: 'failed',
                        message: 'Something went wrong!',
                    }
                }

                const userType = response.data?.data?.user?.account_type
                const isSubscribed = response.data?.data?.user?.isSubscribed

                // if (response.data?.data?.user?.account_type !== 'standard') {
                //     return {
                //         status: 'failed',
                //         message: 'User not authorized to access this system',
                //     }
                // }

                dispatch(onSignInSuccess(response.data?.data?.access_token))
                dispatch(setUser(response.data?.data?.user))

                const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY)

                // const redirectUrl = query.get(REDIRECT_URL_KEY)

                if (userType === SUPER_ADMIN) {
                    navigate(appConfig.authenticatedEntryAdminPath)
                } else {
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : userType === SUPER_ADMIN
                            ? appConfig.authenticatedEntryAdminPath
                            : userType === MANAGER
                            ? isSubscribed
                                ? appConfig.authenticatedEntryPath
                                : appConfig.publicPlansPath
                            : appConfig.authenticatedEntryPath
                    )
                }

                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            if (
                errors.message ===
                'Firebase: Error (auth/admin-restricted-operation).'
            ) {
                return {
                    status: 'failed',
                    message:
                        'User unauthorized. Please contact admin for access.',
                }
            } else if (
                errors.response &&
                errors.response.data &&
                errors.response.data.message === 'User not found'
            ) {
                return {
                    status: 'failed',
                    message: 'No account registered with this address',
                }
            } else if (
                errors.response &&
                errors.response.data &&
                errors.response.data.message === 'User email is not verified'
            ) {
                // toast.push(
                //     <Notification
                //         title={errors.response.data.message}
                //         type="danger"
                //         duration={2500}
                //     >
                //        Kindly verify your email
                //     </Notification>,
                //     {
                //         placement: 'top-center',
                //     }
                // )
                return {
                    status: 'failed',
                    message: 'Please verify your email',
                }
            }
            return firebaseErrorHandler(errors)
        }
    }

    const signInWithGoogle = async () => {
        try {
            const resp = await apiSignInWithGoogle()
            if (resp.user) {
                const tokens = resp.user.stsTokenManager
                const { accessToken } = tokens
                const data = {
                    google_token: accessToken,
                }
                const response = await apiGetUserHFM(data)

                if (response.status !== 200) {
                    return {
                        status: 'failed',
                        message: 'Something went wrong!',
                    }
                }

                dispatch(onSignInSuccess(response.data?.data?.access_token))
                dispatch(setUser(response.data?.data?.user))

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            if (
                errors.message ===
                'Firebase: Error (auth/admin-restricted-operation).'
            ) {
                return {
                    status: 'failed',
                    message:
                        'User unauthorized. Please contact admin for access.',
                }
            }
            return firebaseErrorHandler(errors)
        }
    }

    const signInMetaMask = async (values) => {
        try {
            const resp = await apiSignInMetaMask(values)
            if (resp.data.success) {
                const apiResponse = resp.data.data

                dispatch(onSignInSuccess(apiResponse.access_token))
                dispatch(setUser(apiResponse.user))

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )

                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message:
                    errors.reponse.data.message ??
                    'User unauthorized. Please contact admin for access.',
            }
        }
    }

    // const signUp = async (values) => {
    //     try {
    //         const resp = await apiSignUp(values)
    //         if (resp.data) {
    //             const { token } = resp.data
    //             dispatch(onSignInSuccess(token))
    //             if (resp.data.user) {
    //                 dispatch(
    //                     setUser(
    //                         resp.data.user || {
    //                             avatar: '',
    //                             userName: 'Anonymous',
    //                             authority: ['USER'],
    //                             email: '',
    //                         }
    //                     )
    //                 )
    //             }
    //             const redirectUrl = query.get(REDIRECT_URL_KEY)
    //             navigate(
    //                 redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
    //             )
    //             return {
    //                 status: 'success',
    //                 message: '',
    //             }
    //         }
    //     } catch (errors) {
    //         return {
    //             status: 'failed',
    //             message: errors?.response?.data?.message || errors.toString(),
    //         }
    //     }
    // }

    const register = async (values) => {
        try {
            const resp = await apiRegister(values)
            if (resp.data) {
                toast.push(
                    <Notification
                        title={'Successfully registered.'}
                        type="success"
                        duration={2500}
                    >
                        Please Check your email for account verification.
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                )
                navigate(appConfig.unAuthenticatedEntryPath)
                return {
                    status: 'success',
                    message: 'successfully registered',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUpWithGoogle = async (accountType) => {
        try {
            const resp = await apiSignInWithGoogle()
            if (resp.user) {
                const email = resp.user.email

                const signupData = {
                    email: email,
                    account_type: accountType,
                }
                const result = await apiGoogleSignUp(signupData)

                if (result.data.data.success) {
                    toast.push(
                        <Notification title={''} type="success" duration={2500}>
                            Successfully registered.
                        </Notification>,
                        {
                            placement: 'top-center',
                        }
                    )
                    navigate(appConfig.unAuthenticatedEntryPath)
                    return {
                        status: 'success',
                        message: '',
                    }
                } else {
                    return {
                        status: 'failed',
                        message: 'Something went wrong!',
                    }
                }
            }
        } catch (errors) {
            if (errors?.response?.data?.message === 'User already exists') {
                return {
                    status: 'failed',
                    message: 'User already exists',
                }
            } else if (
                errors.response &&
                errors.response.data &&
                errors.response.data.message === 'User email is not verified'
            ) {
                return {
                    status: 'failed',
                    message: 'Please verify your email',
                }
            }

            return firebaseErrorHandler(errors)
        }
    }

    const signUpMetaMask = async (values) => {
        try {
            const resp = await apiMetamaskSignUp(values)
            if (resp.data.data.success) {
                toast.push(
                    <Notification title={''} type="success" duration={2500}>
                        Successfully registered.
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                )

                navigate(appConfig.unAuthenticatedEntryPath)

                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message:
                    errors.response?.data?.message ??
                    'User unauthorized. Please contact admin for access.',
            }
        }
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        // signUp,
        register,
        signUpWithGoogle,
        signUpMetaMask,
        signInWithGoogle,
        signOut,
        signInMetaMask,
    }
}

export default useAuth
