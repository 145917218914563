import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import error from './error/errorSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        error,
        ...asyncReducers,
    })

    if (action.type === 'auth/session/onSignOutSuccess') {
        state = undefined
    }

    return combinedReducer(state, action)
}

export default rootReducer
