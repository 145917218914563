import React from 'react'

const AvatarSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width={width} height={height} rx="2" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3974 6.42424L18.609 8.6654C19.1074 9.13888 19.1074 9.95959 18.609 10.4331L12.8464 16.2727V8.25505L14.6531 6.42424C15.1203 5.91919 15.9302 5.91919 16.3974 6.42424ZM6.02344 16.1875V5.25C6.02344 4.5625 6.56889 4 7.23556 4H10.2659C10.9325 4 11.478 4.5625 11.478 5.25V16.1875C11.478 17.75 10.2659 19 8.75071 19C7.23556 19 6.02344 17.75 6.02344 16.1875ZM8.75439 17.6364C9.50439 17.6364 10.118 17.0227 10.118 16.2727C10.118 15.5227 9.50439 14.9091 8.75439 14.9091C8.00439 14.9091 7.39076 15.5227 7.39076 16.2727C7.39076 17.0227 8.00439 17.6364 8.75439 17.6364Z"
            fill="white"
        />
    </svg>
)

export default AvatarSvg
