const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/home',
    authenticatedEntryAdminPath: '/admin/standard-users',
    unAuthenticatedEntryPath: '/sign-in',
    publicPlansPath: '/plans',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
