import React from 'react'

const BitmartSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 800 800"
    >
        <rect width={width} height={height} rx="2" fill={color} />

        <path
            className="st0"
            d="M637.6,388c33.1-41.9,51.1-93.7,51-147.1c0-131.3-106.4-233.4-237.6-233.4H162.4v29.7h282.2
	c116,0,210,89.8,210,205.8c0.1,45.1-14.4,89-41.3,125.2L637.6,388z"
            fill={color}
        />
        <path
            className="st1"
            d="M605.4,363.4C637,331.3,654.6,288,654.6,243c0-94.9-76.9-171.8-171.8-171.9h-244v29.7h250.3
	c77.3,0,140.1,62.6,140.1,139.9c0,0,0,0.1,0,0.1c0,41.3-18.2,80.6-49.9,107.2L605.4,363.4z"
            fill={color}
        />
        <path
            className="st2"
            d="M558.3,341c40.1-17.5,70.9-55.8,70.9-100.1c0-60.9-57.9-110.3-118.8-110.3H298.2v17h210.1
	c50.3,0,99.7,40.9,99.7,91.2c0,40.8-32.3,75.4-71.4,87L558.3,341z"
            fill={color}
        />
        <path
            className="st3"
            d="M77.5,762.8h46.7v29.7H77.5V762.8z"
            fill={color}
        />
        <path
            className="st4"
            d="M124.2,703.4h186.7v21.2H124.2L124.2,703.4z"
            fill={color}
        />
        <path
            className="st5"
            d="M124.2,571.9h199.4v17H124.2L124.2,571.9z"
            fill={color}
        />
        <path
            className="st6"
            d="M361.8,571.9h127.3v17H361.8V571.9z"
            fill={color}
        />
        <path
            className="st7"
            d="M162.4,512.4h67.9v17h-67.9V512.4z"
            fill={color}
        />
        <path
            className="st8"
            d="M268.5,512.4h220.6v17H268.5V512.4z"
            fill={color}
        />
        <path
            className="st9"
            d="M340.6,245.1h144.3v17H340.6V245.1z"
            fill={color}
        />
        <path
            className="st10"
            d="M170.9,130.6h80.6v17h-80.6V130.6z"
            fill={color}
        />
        <path
            className="st11"
            d="M192.1,194.2h297v17h-297L192.1,194.2z"
            fill={color}
        />
        <path
            className="st12"
            d="M115.7,245.1h182.4v17H115.7L115.7,245.1z  fill={props.color}"
        />
        <path
            className="st0"
            d="M166.6,758.6v29.7h322.5c131.3,0,237.6-102.1,237.6-233.4c0-131.2-106.4-237.6-237.6-237.6l0,0H281.2v25.5
	h201.6c116,0,210,94.1,210,210.1c0,116-94,205.8-210,205.8L166.6,758.6z"
            fill={color}
        />
        <path
            className="st1"
            d="M217.5,380.9v29.7h309.8c77.3,0,140,62.7,140,140c0,0,0,0,0,0c0,77.3-62.7,140-140,140l0,0H344.8v33.9h176
	c94.9,0,171.8-76.9,171.8-171.8c0,0,0,0,0,0c0-94.9-76.9-171.8-171.8-171.9H217.5z"
            fill={color}
        />
        <path
            className="st2"
            d="M306.7,444.6v17h239.7c50.3,0,99.7,40.9,99.7,91.2c0,50.3-49.3,91.2-99.7,91.2H73.3v21.2h475.2
	c60.9,0,118.8-49.4,118.8-110.3s-57.9-110.3-118.8-110.3H306.7z"
            fill={color}
        />
    </svg>
)

export default BitmartSvg
