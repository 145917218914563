import React from 'react'

const FelixSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width={width} height={height} rx="2" fill="#48C3CC" />
        <path
            d="M3.22988 23.9967L3.14464 23.823C2.84423 23.1824 2.61771 22.5161 2.46862 21.8344C1.85932 19.2174 1.62657 16.8437 3.14464 17.0081C4.66271 17.1725 7.97163 21.0257 7.97163 21.0257C7.97163 21.0257 8.24441 21.1947 6.72634 21.7549C6.91535 21.7741 9.4304 20.7069 11.9618 20.7069C14.1195 20.7069 15.7718 21.4712 17.0415 21.79C16.5745 21.6508 15.8236 21.1987 15.7962 21.029C15.7688 20.8593 19.6151 16.853 20.7003 17.0207C22.3147 17.2798 22.2028 19.9061 21.5572 21.9988C21.4089 22.4741 21.1043 23.0773 20.8404 23.6102C20.7744 23.7428 20.6229 23.9954 20.6536 23.9954L3.22988 24V23.9967Z"
            fill={color}
        />
    </svg>
)

export default FelixSvg
