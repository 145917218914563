import React from 'react'

const FTXSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width={width} height={height} rx="2" fill={color} />
        <path
            d="M8.5498 19.2212H12.2459V15.4426H8.5498V19.2212Z"
            fill={color}
        />
        <path
            d="M15.5251 10.2192H8.5498V13.999H15.5251H16.7936V10.2205H15.5251V10.2192Z"
            fill={color}
        />
        <path
            d="M8.5498 5.00024V8.77881H19.9992V5.00024H8.5498Z"
            fill={color}
        />
        <path d="M4 13.999H7.69484V10.2192H4V13.999Z" fill={color} />
    </svg>
)

export default FTXSvg
