import React from 'react'

const KrakenSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width={width} height={height} rx="2" fill="white" />
        <path
            d="M11.4854 6.01674C12.8755 5.92774 14.2924 6.19472 15.5355 6.79226C17.3801 7.65679 18.8505 9.20785 19.5322 11.0386C19.8396 11.8523 20 12.7041 20 13.5686V16.7597C20 16.8869 20 17.0267 19.9733 17.1538C19.8931 17.4717 19.6525 17.7641 19.3317 17.9039C19.1044 18.0057 18.8505 18.0184 18.6232 17.9802C18.1153 17.8912 17.7143 17.4335 17.7143 16.9377C17.7143 15.946 17.7143 14.9544 17.7143 13.9627C17.7277 13.683 17.7277 13.3779 17.5673 13.1363C17.3133 12.6787 16.6717 12.4625 16.1771 12.6532C15.7494 12.7931 15.4419 13.2126 15.4286 13.6449C15.4286 14.6493 15.4286 15.6536 15.4286 16.658C15.4286 16.8487 15.4419 17.0394 15.3751 17.2174C15.2682 17.5734 14.9607 17.8531 14.5865 17.9548C14.0919 18.0819 13.5305 17.8785 13.2899 17.4463C13.1161 17.192 13.1295 16.8741 13.1429 16.5817C13.1429 15.5774 13.1429 14.5857 13.1429 13.5813C13.1295 13.0728 12.6884 12.6151 12.1671 12.5515C11.8195 12.5007 11.4586 12.6151 11.2047 12.8439C10.9774 13.0473 10.8571 13.3398 10.8571 13.6322V16.5436C10.8571 16.7851 10.8839 17.0394 10.7903 17.2683C10.6433 17.6878 10.2022 17.9929 9.73434 17.9802C9.25313 18.0056 8.7853 17.6878 8.63826 17.2683C8.55806 17.0521 8.57143 16.836 8.57143 16.6072C8.57143 15.6028 8.57143 14.5984 8.57143 13.594C8.57143 13.0982 8.15706 12.6405 7.64912 12.5515C7.18129 12.4625 6.67335 12.6787 6.43275 13.0855C6.33918 13.2508 6.28571 13.4415 6.28571 13.6322V16.8996C6.28571 17.2174 6.13868 17.5352 5.88471 17.7387C5.49708 18.0565 4.86884 18.0819 4.46784 17.7895C4.17377 17.5988 4 17.2683 4 16.925V13.6195C4 12.3735 4.33417 11.1276 4.96241 10.0215C5.47034 9.11885 6.17878 8.31789 7.02089 7.68221C8.26399 6.69055 9.85464 6.10573 11.4854 6.01674Z"
            fill={color}
        />
    </svg>
)

export default KrakenSvg
