import axios from 'axios'
import {
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
} from 'firebase/auth'
import { auth, provider } from '../firebase'
import ApiService from './ApiService'

const apiBaseURL = process.env.REACT_APP_API_URL

// NOTE: We need to use firebase for login and signup processes

export async function apiSignIn(data) {
    return signInWithEmailAndPassword(auth, data.userName, data.password)
}

export async function apiSignInWithGoogle() {
    return signInWithPopup(auth, provider)
}

export async function apiGetUserHFM(data) {
    return axios.post(
        apiBaseURL + `/api/auth/google-token/authentication`,
        data
    )
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiGoogleSignUp(data) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/register/google-users',
        method: 'post',
        data,
    })
}

export async function apiMetamaskSignUp(data) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/register/metamask-users',
        method: 'post',
        data,
    })
}

export async function apiRegister(data) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/register',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return signOut(auth)
    // return ApiService.fetchData({
    //     url: '/sign-out',
    //     method: 'post',
    //     data,
    // })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/auth/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/auth/change-password',
        method: 'put',
        data,
    })
}

export async function apiUserMetaMask(data) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/users',
        method: 'put',
        data,
    })
}

export async function apiMetaMaskNonce(data) {
    return axios.get(apiBaseURL + `/api/auth/meta-mask?public_address=${data}`)
}

export async function apiSignInMetaMask(data) {
    return axios.post(apiBaseURL + '/api/auth/meta-mask/authentication', {
        ...data,
    })
}


export async function apiGetUserAvailableRoles() {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/roles/available-user-roles',
        method: 'get',
    })
}

export async function apiUpdateUserRoleStatus(data) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/roles/switch-user-role?role=' + data.role + '&account_type=' + data.account_type,
        method: 'get',
    })
}

export async function apiCheckSubscriptionStatus() {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/subscription/is-subscribed',
        method: 'get',
    })
}

export async function apiVerifyEmail(token) {
    return ApiService.fetchData({
        url: apiBaseURL + '/api/auth/verify-email?token=' + token,
        method: 'post',
    })
}