import React from 'react'

const KucoinSvg = ({ color, height = 24, width = 24 }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 800 800"
    >
        <rect width={width} height={height} rx="2" fill={color} />

        <g id="Layer_x0020_1">
            <path
                id="形状"
                className="st1"
                d="M267.6,400.1l233.6,233.6l147.5-147.5c26.4-23.7,66.7-22.7,91.8,2.4c25.1,25.1,26.2,65.4,2.4,91.8
		L548.2,775.1c-26.2,25.7-68,25.7-94.2,0L173.3,494.3v166.9c0,36.8-29.8,66.7-66.7,66.7S40,698,40,661.1V138.8
		c0-36.8,29.8-66.7,66.7-66.7s66.7,29.9,66.7,66.7v166.9L454,24.9c26.1-25.7,68.1-25.7,94.2,0l194.7,194.6
		c23.7,26.4,22.7,66.7-2.4,91.8c-25.1,25.1-65.4,26.2-91.8,2.4L501.3,166.2L267.6,400.1L267.6,400.1z M501.3,333.3L501.3,333.3
		c-27,0-51.4,16.2-61.7,41.2c-10.3,25-4.6,53.7,14.4,72.8c19.1,19.1,47.8,24.8,72.8,14.5c25-10.3,41.2-34.7,41.2-61.7
		c0-17.7-7-34.7-19.5-47.2C536,340.3,519,333.3,501.3,333.3L501.3,333.3L501.3,333.3z"
                fill={color}
            />
        </g>
    </svg>
)

export default KucoinSvg
