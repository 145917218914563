import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'snapShotList/state',
    initialState: {
        editConfirmation: false,
        selectedSnapShot: {},
        sortedColumn: () => {},
        drawerOpen: false,
        sync_time: {},
        is_snapshot: 0,
        snapshot:false
    },
    reducers: {
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedSnapShot: (state, action) => {
            state.selectedSnapShot = action.payload
        },

        setDrawerOpen: (state) => {
            state.drawerOpen = true
        },
        setDrawerClose: (state) => {
            state.drawerOpen = false
        },
        toggleIsSnapShot: (state, action) => {
            state.is_snapshot = action.payload
        },
        setSnapshot:(state,action)=>{
            state.snapshot=action.payload
        },
        setSyncTime: (state, action) => {
            state.sync_time = action.payload
        },
        toggleEditConfirmation: (state, action) => {
            state.editConfirmation = action.payload
        },
    },
})

export const {
    setSortedColumn,
    setSelectedSnapShot,
    toggleEditConfirmation,
    setDrawerOpen,
    setDrawerClose,
    setSyncTime,
    toggleIsSnapShot,
    setSnapshot
} = stateSlice.actions

export default stateSlice.reducer
