import { PropTypes } from 'prop-types'
import {
    AvatarSvg,
    BinanceSvg,
    BinanceUSSvg,
    BitmartSvg,
    CoinFlexSvg,
    CoinbaseSvg,
    FTXSvg,
    FelixSvg,
    HuobiSvg,
    KrakenSvg,
    KucoinSvg,
    PoloniexFutureSvg,
    PoloniexSpotSvg,
} from 'assets/svg'

const ProviderLogo = ({ provider, color, size }) => {
    provider = provider?.toLowerCase()
    const attributes = {
        color: color,
        height: size?.height || 24,
        width: size?.width || 24,
    }
    switch (provider) {
        case 'ftx':
            return <FTXSvg {...attributes} />
        case 'binance':
            return <BinanceSvg {...attributes} />
        case 'kraken':
            return <KrakenSvg {...attributes} />
        case 'binance us':
        case 'binanceus':
            return <BinanceUSSvg {...attributes} />
        case 'felix':
            return <FelixSvg {...attributes} />
        case 'bitmart':
            return <BitmartSvg {...attributes} />
        case 'huobi':
            return <HuobiSvg {...attributes} />
        case 'coinbase':
            return <CoinbaseSvg {...attributes} />
        case 'coinflex':
            return <CoinFlexSvg {...attributes} />
        case 'kucoin':
            return <KucoinSvg {...attributes} />
        case 'poloniexfutures':
            return <PoloniexFutureSvg {...attributes} />
        case 'poloniex':
            return <PoloniexSpotSvg {...attributes} />
        default:
            return <AvatarSvg {...attributes} />
    }
}

ProviderLogo.propTypes = {
    provider: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.object,
}

ProviderLogo.defaultProps = {
    provider: '',
    color: '#CCCCCC',
    size: { height: 24, width: 24 },
}

export default ProviderLogo
